<template>
  <!-- 没用el-aside因为自带300宽度 -->
  <div class="_aside">
    <el-menu
        :default-active="onRoutes"
        :collapse="navShow"
        v-loading.fullscreen.lock="fullscreenLoading"
        class="el-menu-vertical-demo menu"
        unique-opened
        router
        :default-openeds="openeds"
    >
      <template v-for="(menu_one,i) in menuData.children">
        <el-submenu v-if=""  :key="i" :index="i + '' ">
          <template slot="title">
            <i :class="menu_one.icon"></i>
            <span>{{menu_one.menuName}}</span>
          </template>

          <el-menu-item
              v-for="(menu_two,i) in menu_one.children"
              :key="i"
              :index="menu_two.path"
          >
            <i :class="menu_two.icon"></i>
            <span @click="handleClick(menu_two.menuName)">{{menu_two.menuName}}</span>
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>
<style lang="scss" scoped>
._aside {
  .menu {
    height: 100%;
  }
  .menu:not(.el-menu--collapse) {
    //设置才可以有折叠特效
    width: 200px;
  }
}
</style>

<script>
import bus from "../common/bus";
import axios from 'axios'
import { mapActions } from 'vuex'
import { Loading } from 'element-ui'
export default {
  data() {
    return {
      openeds: ['0'],
      fullscreenLoading: false,
      navShow: false, //导航是否折叠
      menuData: {},
      information: this.$store.state.information
    };
  },
  created() {
    bus.$on("navShowChange", navShow => {
      this.navShow = navShow;
    });
    var _self = this;

  },
  watch: {
    getDeveloperInfo(newValue) {
      if (newValue.authorManagement == true) {
        const citymenu = JSON.parse(sessionStorage.getItem('menu'));
        const mymetan = {};
        const children = [];
        children.push(citymenu[0].children[0]);
        mymetan.children = children;
        this.menuData = mymetan;
      }else if(newValue.cityDistribution == true){
        //这里得加一个for循坏 或者forEach查询
        const citymenu = JSON.parse(sessionStorage.getItem('menu'));
        citymenu[0].children.forEach(item => {
          if ( item.menuId == '2') {
            this.menuData = item;
          }
        })
      }else if(newValue.controlTime == true){
        const citymenu = JSON.parse(sessionStorage.getItem('menu'));
        citymenu[0].children.forEach(item => {
          if ( item.menuId == '3') {
            this.menuData = item;
          }
        })
      }
    }
  },
  mounted() {
    const loading = this.$loading({
      lock: true,
      text: '加载中....',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(() => {
      this.getuser();
    }, 1000);
    setTimeout(() => {
      loading.close();
    }, 1000);
  },
  methods: {
    ...mapActions({
      setDeveloperInfo: 'developer/setDeveloperInfo',
    }),
    handleClick(name){
      if(name === '计划运单'){
        localStorage.removeItem('plan')
      }
      if(name === '运单管理'){
        localStorage.removeItem('way')
      }
    },
    getuser:function(){
      let citymenu = JSON.parse(sessionStorage.getItem('menu'));
      if(citymenu[0].children.length == 0){
        //表示用户没有被赋值菜单
        this.$alert("您的账号还未赋值菜单权限，请联系管理员", '提示', {
          confirmButtonText: '确定',
        });
        sessionStorage.removeItem('menu');
        sessionStorage.removeItem('userButtons');
        this.$cookies.set("token",'',-1, '/');
        this.$router.replace('/login');

      }else {
        if(citymenu[0].children[0].menuId == '1'){
          //这个是权限管理的需要处理下数据
          const mymetan = {};
          const children = [];
          children.push(citymenu[0].children[0]);
          mymetan.children = children;
          this.menuData = mymetan;
          this.setDeveloperInfo({
            data: {
              authorManagement: true,
              cityDistribution: false,
              controlTime: false,
            }
          })
        }else if(citymenu[0].children[0].menuId == '2'){
          this.setDeveloperInfo({
            data: {
              authorManagement: false,
              cityDistribution: true,
              controlTime: false,
            }
          })
          this.menuData = citymenu[0].children[0];
        }else if(citymenu[0].children[0].menuId == '3'){
          this.setDeveloperInfo({
            data: {
              authorManagement: false,
              cityDistribution: false,
              controlTime: true,
            }
          })
          this.menuData = citymenu[0].children[0];
        }
      }
    }
  },
  computed: {
    onRoutes() {
      //监听路由,设置默认激活项目
      return this.$route.path.replace("/", "");
    },
    getDeveloperInfo() {
      return this.$store.state.developer.developerInfo;
    }
  }
};
</script>
