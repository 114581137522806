<template>
  <el-header class="_header">
    <div class="left">
      <ul>
        <li class="collapse-btn" @click="navChangeShow">
          <i class="el-icon-menu"></i>
        </li>
        <li class="tydlogo"></li>
        <li class="nohover">城市配管理系统</li>
      </ul>
    </div>
    <div class="right">
      <ul>
        <li>
          <el-dropdown trigger="click" v-if="menuhandFlag" @command="menuhandleCommand">
            <span class="el-dropdown-link">
              切换模块<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item,index) in menu[0].children"
                                v-text="item.menuName"
                                :command="item.menuId"
              ></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li>
          <i class="el-icon-rank fullscreen" @click="handleFullScreen"></i>
        </li>
        <!--消息通知-->
        <!--<li>-->
        <!--<i class="el-icon-bell notice" @click="notice"></i>-->
        <!--</li>-->
        <li>
          <el-dropdown class="white font" trigger="click" placement="bottom" @command="handleCommand">
            <span class="el-dropdown-link">
              {{ username }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="aboult">修改密码</el-dropdown-item>
              <el-dropdown-item command="user_information">个人信息</el-dropdown-item>
              <!--<el-dropdown-item command="download">我的下载</el-dropdown-item>-->
              <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <el-dialog
        title="个人信息"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-form ref="form" :model="form">
        <el-card class="box-card">
          <el-row>
            <el-col :span="6">用户名：</el-col>
            <el-col :span="18">{{ form.username }}</el-col>
          </el-row>
        </el-card>
        <el-card class="box-card" style="margin-top: 5px">
          <el-row>
            <el-col :span="6">姓名：</el-col>
            <el-col :span="18">{{ form.name }}</el-col>
          </el-row>
        </el-card>
        <el-card class="box-card" style="margin-top: 5px">
          <el-row>
            <el-col :span="6">联系方式：</el-col>
            <el-col :span="18">{{ form.phone }}</el-col>
          </el-row>
        </el-card>
        <el-card class="box-card" style="margin-top: 5px">
          <el-row>
            <el-col :span="6">公司名称：</el-col>
            <el-col :span="18">{{ form.companyName }}</el-col>
          </el-row>
        </el-card>
        <el-card class="box-card" style="margin-top: 5px">
          <el-row>
            <el-col :span="6">公司编码：</el-col>
            <el-col :span="18">{{ form.companyId }}</el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-link type="danger" :underline="false">提示:公司编码是作为司机注册绑定凭证哦</el-link>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
    </el-dialog>

  </el-header>
</template>
<script>
import Vue from 'vue'
import {mapGetters} from 'vuex'
import {mapActions} from 'vuex'
import axios from 'axios'
import bus from "../common/bus";

export default {
  data() {
    return {
      navShow: false, //左侧导航是否折叠
      fullscreen: false, //全屏
      position: '',//职位
      username: '',//用户名
      userid: '',
      menuhandFlag: true,
      dialogVisible: false,
      tagsList: [],
      form: {
        id: '',
        username: '',
        name: '',
        gender: '',
        phone: '',
        companyId: '',
        companyName: '',
        roleEnum: ''
      },
      menu: [
        {
          "path": null,
          "children": [
            {
              "path": "authorManagement",
              "children": [],
              "menuId": "1",
              "menuName": "权限管理",
              "menuType": 1,
              "parentId": "00"
            },
            {
              "path": "cityDistribution",
              "children": [],
              "menuId": "2",
              "menuName": "城市配管理",
              "menuType": 1,
              "parentId": "00"
            },
            {
              "path": "controlTime",
              "children": [],
              "menuId": "3",
              "menuName": "定时器管理",
              "menuType": 1,
              "parentId": "00"
            }
          ],
          "menuId": "00",
          "menuName": "tms",
          "menuType": 1,
          "parentId": "0"
        }
      ]

    };
  },
  async created() {
    //先获取个人信息
    let resVerify = await axios.get(`${this.$apiPath}/api/auth/verify`)
    this.username = resVerify.data.username;
    this.userid = resVerify.data.id;
    //拿到个人信息后获取要存取的数据
    let res = await axios.get(`${this.$apiPath}/api/pm/user/findUserInfosWebById/` + this.userid)
    this.form = res.data.data;
    sessionStorage.setItem('roleEnum', this.form.roleEnum)
    //确保存完之后发送标识为homepage组件再去加载子组件
    this.$emit('setShow',true)
  },
  mounted() {
    setTimeout(() => {
      let wholemenu = JSON.parse(sessionStorage.getItem('menu'));
      if (wholemenu[0].children.length == 1) {
        this.menuhandFlag = false;
      } else {
        this.menu = wholemenu;
        this.menuhandFlag = true;
      }
    }, 1000);
  },
  methods: {
    ...mapGetters({
      getDeveloperInfo: 'developer/getDeveloperInfo',
    }),
    ...mapActions({
      setDeveloperInfo: 'developer/setDeveloperInfo',
    }),
    navChangeShow() {
      //切换左侧导航展示/折叠
      this.navShow = !this.navShow;
      bus.$emit("navShowChange", this.navShow);
    },
    getCarrierCode() {
      axios.get(`${this.$apiPath}/api/pm/user/findUserInfosWebById/` + this.userid).then(res => {
        this.form = res.data.data;
        sessionStorage.setItem('roleEnum', this.form.roleEnum)
      })
    },
    menuhandleCommand(command) {
      if (command == '1') {
        this.setDeveloperInfo({
          data: {
            authorManagement: true,
            cityDistribution: false,
            controlTime: false,
          }
        })
      } else if (command == '2') {
        this.setDeveloperInfo({
          data: {
            authorManagement: false,
            cityDistribution: true,
            controlTime: false,
          }
        })
      } else if (command == '3') {
        this.setDeveloperInfo({
          data: {
            authorManagement: false,
            cityDistribution: false,
            controlTime: true,
          }
        })
      }
    },
    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    notice() {
      this.$message('当前暂无消息通知');
    },
    handleClose() {
      this.dialogVisible = false;
    },
    //下拉框事件
    handleCommand(command) {
      if (command == 'loginout') {
        this.$confirm('此操作将退出系统, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.get(`${this.$apiPath}/api/pm/login/loginOut/` + this.userid).then(res => {
            sessionStorage.removeItem('menu');
            sessionStorage.removeItem('userButtons');
            sessionStorage.removeItem('userPermissions');
            sessionStorage.removeItem('roleEnum');
            this.$cookies.set("token", '', -1, '/');
            this.$router.replace('/login');
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
      } else if (command == 'aboult') {
        this.$router.replace('/changePassword');
      } else if (command == 'download') {
        //跳到我的下载页面
        this.$router.replace('/download');
      } else if (command == 'user_information') {
        //跳到用户个人信息页面
        //this.$router.replace('/user_information');
        this.dialogVisible = true;

      }
    }
  }
};
</script>

<style lang="scss" scoped>
._header {
  padding: 0px;
  background-color: #409eff;
  display: flex;
  height: 50px !important;
  flex-flow: row nowrap;
  justify-content: space-between;
  color: white;

  ul {
    list-style: none;

    li {
      float: left;
      line-height: 50px;
      height: 50px;
      text-align: center;
      padding: 0 10px;
      cursor: pointer;
    }

    li:hover {
      background-color: #337ecc;
    }

    i {
      line-height: 50px;
    }
  }

  .nohover {
    pointer-events: none; //禁用hover
  }

  .left {
    .collapse-btn {
      width: 45px;
    }

    .title {
      font-size: 18px;
    }

    .tydlogo {
      width: 69px;
      height: 25px;
      padding: 0;
      margin-top: 15px;
      background-image: url("../../assets/tyd.png");
    }
  }

  .right {
    margin-right: 50px;

    .fullscreen {
      transform: rotate(45deg); //旋转45度
      font-size: 22px;
      width: 40px;
    }

    .notice {
      width: 40px;
    }

    .el-dropdown-link {
      color: white;
      padding: 22px 0px;
    }

    .logobox {
      width: 45px;
      height: 45px;
      background-image: url("../../assets/userlogo.jpg");
      background-size: 100%;
      border-radius: 50%;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      margin-top: 7px;
    }
  }
}
</style>
